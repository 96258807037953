import { useEffect, useState } from "react";

const useTheme = () => {

    const [theme, setTheme] = useState(localStorage.getItem('adtheme') || 'light');

    useEffect(()=>{
        localStorage.setItem('adtheme', theme);
        if (localStorage.adtheme === 'dark' || (!('adtheme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
            } else {
            document.documentElement.classList.remove('dark')
            }
    },[theme]);

    return {theme, setTheme}
}

export default useTheme;