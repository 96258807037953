import { useEffect } from "react";
import "../app.css";
import Banner from "./Banner";
import ReactGA from "react-ga4";
import GetInTouch from "./GetInTouch";
import Footer from "./Footer";

const Home = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing page" });
  }, []);

  return (
    <>
      <main className="wrapper px-[10vw] sm:px-[4vw] md:px-[2vw]">
        <Banner />
        <GetInTouch />
        <Footer />
      </main>
    </>
  );
};

export default Home;
