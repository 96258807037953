import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PiUser } from "react-icons/pi";
import { PiEnvelopeSimple } from "react-icons/pi";
import { PiPhone } from "react-icons/pi";
import { PiChatText } from "react-icons/pi";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";
import { useDispatch } from "react-redux";
import { sendMessage } from "../redux/actions/emailActions";

const GetInTouch = () => {
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "all" });

  const handleMessageSend = async (data) => {
    setIsSending(true);
    await dispatch(sendMessage(data));
    setIsSending(false);
    reset();
  };

  return (
    <section
      className="flex h-screen min-h-[700] relative font-resF1 sm:flex-col xs:flex-col"
      id="getInTouch"
    >
      <div className="relative flex justify-end flex-wrap content-center m-h-[80vh] w-[100%] xs:w-full xs:mt-8 sm:w-full">
        <div className="pt-20 pb-10 relative w-[100%] lg:pb-4 lg:pt-8">
          <h5 className="text-4xl text-resC2 font-bold xs:text-xl sm:text-3xl md:text-3xl">
            Get in touch
          </h5>
          <h3 className="text-base text-resC4 tracking-wide mt-4 dark:text-white xs:mt-1 sm:mt-1">
            Got a question? contact me here
          </h3>
          <span className="absolute bottom-6 left-0 border-b border-[#4B91F1] w-[10%] lg:bottom-1"></span>
        </div>
        <div className="pb-20 w-[100%]">
          <form onSubmit={handleSubmit(handleMessageSend)}>
            <div className="grid grid-cols-12 gap-2">
              <div className="mt-4 col-span-4 sm:col-span-12 xs:col-span-12">
                <div className="flex items-center space-x-2 relative">
                  <PiUser className="absolute top-5 left-2 text-resC1" />
                  <label className="text-sm font-medium text-gray-500 hidden">
                    Name
                  </label>
                </div>
                <input
                  {...register("contactName", {
                    required: "Please fill out name",
                  })}
                  type="text"
                  placeholder="Name"
                  className="mt-1 block w-full border border-gray-300 p-1 outline-none h-[50] indent-7 rounded-md"
                />
                <p className="text-ksC1 text-xs mt-1 dark:text-white">
                  {errors.contactName?.message}
                </p>
              </div>
              <div className="mt-4 col-span-4 sm:col-span-12 xs:col-span-12">
                <div className="flex items-center space-x-2 relative">
                  <PiEnvelopeSimple className="absolute top-5 left-2 text-resC1" />
                  <label className="hidden text-sm font-medium text-gray-500">
                    Email
                  </label>
                </div>
                <input
                  {...register("contactEmail", {
                    required: "Please fill out email",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Email is not valid.",
                    },
                  })}
                  type="text"
                  placeholder="Email"
                  className="mt-1 block w-full rounded-md border border-gray-300 p-1 outline-none h-[50] indent-7"
                />
                <p className="text-ksC1 text-xs mt-1 dark:text-white">
                  {errors.contactEmail?.message}
                </p>
              </div>
              <div className="mt-4 col-span-4 sm:col-span-12 xs:col-span-12">
                <div className="flex items-center space-x-2 relative">
                  <PiPhone className="absolute top-5 left-2 text-resC1" />
                  <label className="hidden text-sm font-medium text-gray-500">
                    Phone
                  </label>
                </div>
                <input
                  {...register("contactPhone", {
                    required: "Please fill out phone",
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message: "Not a valid phone no",
                    },
                  })}
                  type="text"
                  placeholder="Phone"
                  className="mt-1 block w-full rounded-md border border-gray-300 p-1 outline-none h-[50] indent-7"
                />
                <p className="text-ksC1 text-xs mt-1 dark:text-white">
                  {errors.contactPhone?.message}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex items-center space-x-2 relative">
                <PiChatText className="absolute top-5 left-2 text-resC1" />
                <label className="hidden text-sm font-medium text-gray-500">
                  Message
                </label>
              </div>
              <textarea
                {...register("contactMessage", {
                  required: "Please fill out message",
                })}
                type="text"
                placeholder="Message"
                className="mt-1 block w-full rounded-md border border-gray-300 p-1 outline-none indent-8 min-h-[90]"
              />
              <p className="text-ksC1 text-xs mt-1 dark:text-white">
                {errors.contactMessage?.message}
              </p>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row">
              <button
                //onClick={handleMessageSend}
                type="submit"
                className={`animate-imgFloating group bg-resC3 relative w-[170] h-[52px] sm:w-[145] md:w-[145] lg:w-[145] cursor-pointer rounded-md overflow-hidden before:content-[""] before:absolute before:bg-resC1 before:top-0 before:left-0 before:w-full before:h-full before:translate-y-full before:scale-y-50 before:ease-linear before:duration-200 hover:before:translate-y-0 hover:before:scale-y-100 after:content-[""] after:absolute after:bg-resC3 after:top-0 after:left-0 after:w-full after:h-full after:translate-y-0 after:scale-y-100 after:ease-linear after:duration-200 hover:after:-translate-y-full hover:after:scale-y-50 hover:after:delay-0 ${
                  isSending
                    ? "cursor-not-allowed bg-gray-400"
                    : "bg-ksC2 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                }`}
              >
                {isSending ? (
                  <ButtonLoadingSpinner loadingText={"Sending..."} />
                ) : (
                  <div className="relative block top-[2px] w-full h-[26px] overflow-hidden">
                    <span className="absolute left-0 z-10 w-full text-center ease-linear duration-200 first:text-white first:translate-y-6 group-hover:first:translate-y-0">
                      Send
                    </span>
                    <span className="absolute left-0 z-10 w-full text-center ease-linear duration-200 last:translate-y-0 last:text-white group-hover:last:-translate-y-6">
                      Send
                    </span>
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
