import React from "react";
import { ReactTyped } from "react-typed";

const TypedText = () => {
  return (
    <ReactTyped
      strings={["Designer", "Web Developer"]}
      typeSpeed={90}
      backSpeed={70}
      loop
    ></ReactTyped>
  );
};

export default TypedText;
