import React, { useEffect, useState } from "react";
import { AiOutlineMail, AiOutlineLinkedin } from "react-icons/ai";
import { GoCopilot } from "react-icons/go";
import { GrGraphQl } from "react-icons/gr";
import { SiJest } from "react-icons/si";
import { CiDesktopMouse2 } from "react-icons/ci";
import {
  BiLogoJavascript,
  BiLogoNodejs,
  BiLogoReact,
  BiLogoRedux,
  BiLogoStackOverflow,
  BiLogoTailwindCss,
} from "react-icons/bi";
import profile from "../images/amaldas.webp";
import TypedText from "./TypedText";
import { Link } from "react-router-dom";
import Pdf from "../images/AmalSoftwareEngineer.pdf";
import useTheme from "../hooks/useTheme";
import ReactGA from "react-ga4";

const Banner = () => {
  const handleTheme = (e) => {
    theme == "dark" ? setTheme("light") : setTheme("dark");
  };

  const [isChecked, setIsChecked] = useState(
    localStorage.getItem("adtheme") == "dark" ? true : false
  );
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    if (theme == "dark") {
      setIsChecked(true);
      document.body.classList.contains("bg-resC5")
        ? document.body.classList.remove("bg-resC5")
        : "";
      document.body.classList.add("bg-resC6");
    } else {
      setIsChecked(false);
      document.body.classList.contains("bg-resC6")
        ? document.body.classList.remove("bg-resC6")
        : "";
      document.body.classList.add("bg-resC5");
    }
  }, [theme]);

  const handleResumeDownload = () => {
    ReactGA.event({
      category: Pdf,
      action: "click",
    });
  };

  return (
    <section
      className="flex h-screen min-h-[700] relative font-resF1 sm:flex-col xs:flex-col"
      id="home"
    >
      <div className="flex absolute right-7 top-7 items-center z-10 xs:right-0 xs:top-3">
        <span className="text-xs text-resC4 dark:text-white">Light</span>
        <div className="mx-2">
          <label
            htmlFor="checkbox"
            className="bg-resC7 w-[50px] h-[26px] rounded-[50px] relative px-2 py-1 cursor-pointer flex justify-between items-center"
          >
            <input
              type="checkbox"
              id="checkbox"
              className="opacity-0 absolute peer -z-10"
              onClick={handleTheme}
              defaultChecked={isChecked}
            />
            <svg
              width="14"
              className="light"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#c0a631"
                d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"
              ></path>
            </svg>
            <svg
              width="13"
              className="dark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="#6e57e0"
                d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"
              ></path>
            </svg>
            <span className="bg-white w-[18] h-[18] absolute left-[4] top-[4] rounded-full peer-checked:translate-x-6 ease-linear duration-75"></span>
          </label>
        </div>
        <span className="text-xs text-resC4 dark:text-white">Dark</span>
      </div>
      <div className="featured-image relative flex justify-end content-center m-h-[80vh] w-[40%] xs:w-full xs:mt-8 sm:w-full">
        <div className="image m-auto w-[380px] h-[380] animate-imgFloating xs:w-[200] xs:h-[200] sm:w-[290] sm:h-[290] md:w-[290] md:h-[290]">
          <img src={profile} alt="Amal Das S K" />
        </div>
        <div className="absolute w-full h-full">
          <BiLogoRedux className="text-6xl text-resCrd absolute top-[50%] left-[5%] xs:top-[60%] xs:text-4xl lg:-left-[10%]" />
          <GoCopilot className="text-5xl text-resCop absolute top-[25%] left-[15%] xs:top-[40%] xs:text-3xl sm:top-[30%] md:top-[30%] lg:-left-[10%]" />
          <SiJest className="text-5xl text-resJes absolute top-[28%] right-[25%] xs:top-[5%] xs:right-[25%] xs:text-3xl md:top-[50%] md:right-[10%]" />
          <BiLogoJavascript className="text-6xl text-resCjs absolute top-[40%] right-[5%] xs:top-[20%] xs:right-[5%] xs:text-4xl md:top-[30%] md:right-[10%]" />
          <BiLogoReact className="text-6xl text-resCrt absolute top-[15%] left-[50%] xs:top-[2%] xs:left-[10%] xs:text-4xl sm:left-[12%] sm:top-[5%] md:left-[40%] md:top-[20%]" />
          <GrGraphQl className="text-5xl text-resGql absolute bottom-[33%] right-[10%] xs:text-4xl sm:-bottom-[20%] md:bottom-[30%]" />
          <BiLogoTailwindCss className="text-6xl text-resCtw absolute bottom-[10%] right-[10%] xs:text-4xl  xs:-bottom-[5%] sm:-bottom-[5%] md:bottom-[15%]" />
          <BiLogoNodejs className="text-6xl text-resCnd absolute bottom-[10%] left-[30%] xs:-bottom-[20%] xs:text-4xl sm:-bottom-[20%] md:bottom-[15%]" />
        </div>
      </div>
      <div className="flex relative justify-center content-center min-h-[80vh] flex-col w-[60%] pl-5 xs:mt-10 xs:w-full xs:justify-start sm:w-full">
        <div className="featured-text-card">
          <span className="bg-resC2 text-xs text-white rounded-md px-1 py-1">
            Amal Das
          </span>
        </div>
        <div className="text-5xl font-bold text-resC4 my-5 leading-normal dark:text-white xs:text-xl sm:text-3xl md:text-3xl lg:text-4xl">
          <h1>
            Hi, I'm <i className="not-italic">Amal</i>
            <br />
            Creative{" "}
            <span className="capitalize text-resC1">
              <TypedText />
            </span>
            <br />
            Based in Kochi
          </h1>
        </div>
        <div className="mt-10 xs:mt-3">
          <div className="flex items-center xs:flex-col xs:items-start">
            <div className="mr-12 xs:mr-0 xs:mb-7 sm:mr-8 md:mr-7">
              <div className="flex items-center">
                <h2 className="text-6xl text-resC1 xs:text-xl sm:text-4xl md:text-4xl lg:text-5xl">
                  8.5
                </h2>
                <p className="text-sm text-resC4 font-normal pl-2 dark:text-white xs:text-base">
                  Years <br /> of Experience
                </p>
              </div>
            </div>
            <div className="mr-12 xs:mr-0 xs:mb-10 sm:mr-8 md:mr-7">
              <div className="flex items-center">
                <h2 className="text-6xl text-resC1 xs:text-xl sm:text-4xl md:text-4xl lg:text-5xl">
                  3
                </h2>
                <p className="text-sm text-resC4 font-normal pl-2 dark:text-white xs:text-base">
                  MNC's
                </p>
              </div>
            </div>
            {/* <Link
              to={Pdf}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleResumeDownload}
              className='animate-imgFloating group bg-resC3 relative w-[170] h-[52px] sm:w-[145] md:w-[145] lg:w-[145] cursor-pointer rounded-md overflow-hidden before:content-[""] before:absolute before:bg-resC1 before:top-0 before:left-0 before:w-full before:h-full before:translate-y-full before:scale-y-50 before:ease-linear before:duration-200 hover:before:translate-y-0 hover:before:scale-y-100 after:content-[""] after:absolute after:bg-resC3 after:top-0 after:left-0 after:w-full after:h-full after:translate-y-0 after:scale-y-100 after:ease-linear after:duration-200 hover:after:-translate-y-full hover:after:scale-y-50 hover:after:delay-0'
            >
              <div className="relative block top-[16px] w-full h-[26px] overflow-hidden">
                <span className="absolute z-10 w-full text-center ease-linear duration-200 first:text-white first:translate-y-6 group-hover:first:translate-y-0">
                  Download C.V
                </span>
                <span className="absolute z-10 w-full text-center ease-linear duration-200 last:translate-y-0 last:text-white group-hover:last:-translate-y-6">
                  Download C.V
                </span>
              </div>
            </Link> */}
          </div>
        </div>
        <div className="flex mt-20 gap-7 xs:mt-13">
          <Link
            to="mailto:amaldassk@gmail.com"
            className="flex justify-center items-center w-[40px] h-[40px] rounded-full shadow-lg hover:text-resC3 dark:text-white dark:hover:text-resC3 dark:shadow-white dark:shadow"
          >
            <AiOutlineMail />
          </Link>
          <Link
            to="www.linkedin.com/in/amal-das-s-k-0b0bb8118"
            target="_blank"
            className="flex justify-center items-center w-[40px] h-[40px] rounded-full shadow-lg hover:text-resC3 dark:text-white dark:hover:text-resC3 dark:shadow-white dark:shadow"
          >
            <AiOutlineLinkedin />
          </Link>
          <Link
            to="https://stackoverflow.com/users/7677884/amal"
            target="_blank"
            className="flex justify-center items-center w-[40px] h-[40px] rounded-full shadow-lg hover:text-resC3 dark:text-white dark:hover:text-resC3 dark:shadow-white dark:shadow"
          >
            <BiLogoStackOverflow />
          </Link>
        </div>
      </div>
      <div className="absolute bottom-2 left-[50%] -translate-x-[50%] flex justify-center items-center rounded-full shadow-lg p-3">
        <CiDesktopMouse2 className="text-resC3 text-2xl" />
        <p className="text-resC4 pl-1">Scroll Down</p>
      </div>
    </section>
  );
};

export default Banner;
