import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="pb-5 text-center font-resF1">
      <p className="text-base">
        Copyright ©{" "}
        <Link className="text-resC1" to={"https://www.amaldas.tech/"}>
          amaldas.tech
        </Link>{" "}
        - All rights reserved
      </p>
    </div>
  );
};

export default Footer;
