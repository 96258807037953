import { configureStore } from "@reduxjs/toolkit";
import emailReducer from "./slice/emailSlice";

const appStore = configureStore({
  reducer: {
    email: emailReducer,
  },
});

export default appStore;
