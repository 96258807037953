import { createSlice } from "@reduxjs/toolkit";
import { sendMessage } from "../actions/emailActions";

const initialState = {
  message: null,
};

const emailSlice = createSlice({
  name: "email",
  initialState: initialState,
  extraReducers(builder) {
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      //console.log("done");
    });
  },
});

export default emailSlice.reducer;
