import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Home from "./components/Home";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import appStore from "./redux/appStore";
import { Provider } from "react-redux";

const TRACKING_ID = "G-G4FGMWLGQS";
ReactGA.initialize(TRACKING_ID);

const AppLayout = () => {
  // const [user, setUserd] = useState();
  // const { loggedinuser } = useContext(UserContext);

  // useEffect(() => {
  //   //authentication
  //   const data = {
  //     name: "Amal Das",
  //   };
  //   setUserd(data.name);
  // }, []);

  return (
    <Provider store={appStore}>
      {/* <UserContext.Provider> */}
      <div className="app">
        <Outlet />
        <Toaster position="bottom-center" />
      </div>
      {/* </UserContext.Provider> */}
    </Provider>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter}></RouterProvider>);
