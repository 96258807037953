import axios from "axios";

const BASE_URL =
  process.env.REACT_APP_API_URL ?? "https://back-resume-ec.onrender.com/";

const authInterceptor = (req) => {
  req.headers.devName = "Amal Das S K";
  return req;
};

export const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(authInterceptor);

export const handleApiError = () => {
  try {
    const errMessage =
      err.response?.data?.message || "An unexpected error occurred.";
    const data = null;
    return { error: errMessage, data };
  } catch (err) {
    throw new Error("An unexpected error occurred.");
  }
};
